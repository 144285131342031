import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../environments/environment';

export const redirectToLandingGuard: CanActivateFn = (): boolean => {
  if (! environment.production) {
    return true;
  }

  inject(DOCUMENT).location.href = environment.landingUrl;

  return false;
};
