<!--
Ionic does not support client hydration at this time:
https://github.com/ionic-team/ionic-framework/issues/28625#issuecomment-1843919548
https://github.com/angular/angular/issues/52275
-->
@if (isBrowser) {
  <ion-app>
    <app-side-menu></app-side-menu>
    <app-side-menu-nav></app-side-menu-nav>
    <ion-router-outlet id="main-content" [animated]="false"></ion-router-outlet>
    @if (showCookieBanner$ | async) {
      <app-cookie-banner></app-cookie-banner>
    }
  </ion-app>
}
