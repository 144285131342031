import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { LocaleHelper } from './helper/locale.helper';
import { SettingsDataService } from './crowdbuilding-cms/services/data/personal/settings.data.service';
import { ThemeColorsHelper } from './helper/theme-colors.helper';
import { ProfileService } from './crowdbuilding-cms/services/profile.service';
import { AuthHelper } from './crowdbuilding-cms/helper/auth.helper';
import { AuthDataService } from './crowdbuilding-cms/services/data/personal/auth.data.service';
import { MenusDataService } from './crowdbuilding-cms/services/data/all/menus.data.service';
import { DomPurifyHelper } from './helper/dom-purify.helper';
import { CookiePreferencesService } from './cookie-preferences.service';
import { ProfileDataService } from './crowdbuilding-cms/services/data/personal/profile.data.service';
import { CountsDataService } from './crowdbuilding-cms/services/data/personal/counts.data.service';
import * as linkify from 'linkifyjs';
import { CheckForUpdateService } from './check-for-update.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AppInitializerService {

  constructor(
    private localeHelper: LocaleHelper,
    private settingsDataService: SettingsDataService,
    private themeColorsHelper: ThemeColorsHelper,
    private profileService: ProfileService,
    private authHelper: AuthHelper,
    private authDataService: AuthDataService,
    private menusDataService: MenusDataService,
    private domPurifyHelper: DomPurifyHelper,
    private cookiePreferencesService: CookiePreferencesService,
    private profileDataService: ProfileDataService,
    private countsDataService: CountsDataService,
    private checkForUpdateService: CheckForUpdateService,
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) private _platformId: Object
  ) {
    // Noop
  }

  public init() {
    return isPlatformBrowser(this._platformId) ? this.initBrowser() : this.initServer();
  }

  ///////////////////////
  // PRIVATE FUNCTIONS //
  ///////////////////////

  private async initBrowser() {
    this.initLinkify();

    await this.localeHelper.initializeLocale();
    this.checkForUpdateService.initialize();

    await this.authHelper.initialize();

    const promises: Array<Promise<unknown>> = [
      this.settingsDataService.loadItem().catch(() => {/* Noop */}),
      this.menusDataService.loadItems().catch(() => {/* Noop */}),
      this.countsDataService.loadItem().catch(() => {/* Noop */})
    ];

    if (this.authDataService.isLoggedIn()) {
      promises.push(this.profileService.refresh()
        .catch(error => {
          if (error.status == 401) {
            this.authHelper.logout();
          }
        }));
    }

    await Promise.allSettled(promises);

    // If there is a profile, init locale again, this way it uses profile
    if (this.profileDataService.peekProfile()) {
      await this.localeHelper.initializeLocale();
    }

    this.themeColorsHelper.initializeColors();
    this.domPurifyHelper.initializeHooks();
    this.cookiePreferencesService.updateShowContentBannerValue();

    return true;
  }

  /**
   * Note: The server will first run routes and resolvers, after that this functions will be awaited.
   * Next to this, the server does not load profile information other info. This is not rendered server side.
   * This has to do with Ionic not being able to hydrate components.
   */
  private async initServer() {
    await this.localeHelper.initializeLocale();

    return true;
  }

  private initLinkify() {
    linkify.options.defaults.defaultProtocol = 'https';
    linkify.options.defaults.rel = 'noopener noreferrer nofollow';
    linkify.options.defaults.target = '_blank';
  }

}
