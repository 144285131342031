import { Injectable } from '@angular/core';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { SettingsDataService } from '../services/crowdbuilding-cms/services/data/personal/settings.data.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TitleStrategyClass extends TitleStrategy {

  constructor(
    private readonly title: Title,
    private readonly settingsData: SettingsDataService
  ) {
    super();
  }

  public override updateTitle(routerState: RouterStateSnapshot) {
    this.title.setTitle(this.buildTitle(routerState) || this.settingsData.peekItem()?.name || environment.appName);
  }

}
