import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { Device } from '@capacitor/device';
import { TranslocoService } from '@jsverse/transloco';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { Helper } from './helper';
import { AuthDataService } from '../crowdbuilding-cms/services/data/personal/auth.data.service';
import { ProfileService } from '../crowdbuilding-cms/services/profile.service';
import { ProfileDataService } from '../crowdbuilding-cms/services/data/personal/profile.data.service';
import { CookiesHelper } from './cookies.helper';
import { PREFERRED_LANGUAGE_KEY } from '../../constants/app.constants';
import { Request } from 'express';
import { REQUEST } from 'ngx-cookie-service-ssr';

@Injectable({
  providedIn: 'root'
})
export class LocaleHelper {

  public availableLangs$ = new BehaviorSubject<Array<string>>([]);

  constructor(
    private translocoService: TranslocoService,
    private helper: Helper,
    private authDataService: AuthDataService,
    private profileService: ProfileService,
    private profileDataService: ProfileDataService,
    private cookiesHelper: CookiesHelper,
    @Inject(DOCUMENT) private document: Document,
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject(REQUEST) private serverRequest: Request | null
  ) {
    this.setAvailableLangs();
  }

  public async initializeLocale(): Promise<void> {
    const languageCode = await this.getInitialLanguageCode();

    return await this.updateLocale(languageCode);
  }

  public changeLocale(languageCode: string): Promise<void> {
    if (! this.isAvailableLang(languageCode)) {
      return this.initializeLocale();
    }

    if (this.authDataService.isLoggedIn()) {
      this.profileService.updateLocale(languageCode)
        .catch(() => {/* Noop */});
    }

    return this.updateLocale(languageCode);
  }

  ///////////////////////
  // PRIVATE FUNCTIONS //
  ///////////////////////

  private async updateLocale(languageCode: string): Promise<void> {
    const cookieExpireDate = new Date();
    cookieExpireDate.setFullYear(cookieExpireDate.getFullYear() + 2);

    this.cookiesHelper.set(PREFERRED_LANGUAGE_KEY, languageCode, cookieExpireDate);
    this.translocoService.setActiveLang(languageCode);
    await lastValueFrom(this.translocoService.load(languageCode));
    this.document.documentElement.lang = languageCode;
  }

  /**
   * Determine initial language by profile, local storage, device or use default
   * The order of the function calls matter.
   */
  private async getInitialLanguageCode(): Promise<string> {
    return this.getAvailableLangFromProfile() ||
      this.getAvailableLangFromCookie() ||
      await this.getAvailableLangFromDevice() ||
      this.translocoService.getDefaultLang();
  }

  private async getLanguageCodeFromDevice(): Promise<string | undefined> {
    return Device.getLanguageCode()
      .then(result => result.value)
      .catch(() => undefined);
  }

  private isAvailableLang(lang: string): boolean {
    return this.availableLangs$.value.includes(lang);
  }

  private setAvailableLangs() {
    this.availableLangs$.next(this.translocoService.getAvailableLangs()
      .map(lang => {
        if (this.helper.isObject(lang)) {
          return lang.id;
        }

        return lang;
      }));
  }

  //////////////////////////////
  // DETERMINE AVAILABLE LANG //
  //////////////////////////////

  private getAvailableLangFromProfile(): null | string {
    const profile = this.profileDataService.peekProfile();
    if (! profile) {
      return null;
    }

    const locale = profile.locale;
    if (! locale) {
      return null;
    }

    return this.isAvailableLang(locale) ? locale : null;
  }

  private getAvailableLangFromCookie(): null | string {
    const language = this.cookiesHelper.get(PREFERRED_LANGUAGE_KEY);
    if (! language) {
      return null;
    }

    return this.isAvailableLang(language) ? language : null;
  }

  private async getAvailableLangFromDevice(): Promise<null | string> {
    if (isPlatformServer(this.platformId)) {
      return this.serverRequest?.acceptsLanguages(this.availableLangs$.value) || null;
    }

    let language = undefined;
    try {
      language = await this.getLanguageCodeFromDevice();
    } catch (e) {
      return null;
    }

    if (! language) {
      return null;
    }

    return this.isAvailableLang(language) ? language : null;
  }

}
