import { Inject, Injectable, OnDestroy } from '@angular/core';
import { filter, skip, Subject, switchMap, takeUntil } from 'rxjs';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { DOCUMENT } from '@angular/common';
import { RouterService } from './router.service';

@Injectable({
  providedIn: 'root'
})
export class CheckForUpdateService implements OnDestroy {

  private _unsubscribe$ = new Subject<void>();

  constructor(
    private swUpdate: SwUpdate,
    private routerService: RouterService,
    @Inject(DOCUMENT) private document: Document
  ) {
    // Noop
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  public initialize() {
    if (! this.swUpdate.isEnabled) {
      return;
    }

    this.swUpdate.versionUpdates
      .pipe(
        takeUntil(this._unsubscribe$),
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        switchMap(() => this.routerService.url$.pipe(skip(2))),
      )
      .subscribe(() => {
        this.applyUpdate();
      });
  }

  private applyUpdate(): void {
    this.swUpdate.activateUpdate()
      .then(() => this.document.location.reload())
      .catch(() => {/* Noop */});
  }

}
