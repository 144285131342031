import { APP_INITIALIZER, isDevMode, NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration, withHttpTransferCacheOptions } from '@angular/platform-browser';
import { RouteReuseStrategy, TitleStrategy, UrlSerializer } from '@angular/router';

import { IonApp, IonicRouteStrategy, IonRouterOutlet, provideIonicAngular } from '@ionic/angular/standalone';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { SideMenuComponentModule } from './components/side-menu/side-menu.module';
import { SideMenuDesktopComponentModule } from './components/side-menu-desktop/side-menu-desktop.module';
import { SwiperModule } from 'swiper/angular';
import { provideAnimations } from '@angular/platform-browser/animations';
import { SideMenuNavComponentModule } from './components/side-menu-nav/side-menu-nav.module';
import { CookieBannerComponent } from './components/cookie-banner/cookie-banner.component';
import { LowerCaseUrlSerializer } from './classes/providers/lower-case-url-serializer';
import { AppInitializerService } from './services/app-initializer.service';
import { httpInterceptor } from './interceptors/http.interceptor';
import { TitleStrategyClass } from './classes/title-strategy.class';
import { AsyncPipe, IMAGE_LOADER } from '@angular/common';
import { imageLoaderFunction } from './functions/image-loader.function';
import { FunctionalTranspiler, provideTransloco, provideTranslocoTranspiler } from '@jsverse/transloco';
import { provideTranslocoLocale } from '@jsverse/transloco-locale';
import { TranslocoHttpLoader } from './classes/transloco/transloco-http-loader';
import { AppNameResolver } from './classes/transloco/resolvers/app-name.resolver';
import { CopyrightYearAndAppNameResolver } from './classes/transloco/resolvers/copyright-year-and-app-name.resolver';
import { provideServiceWorker } from '@angular/service-worker';
import { Capacitor } from '@capacitor/core';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SwiperModule,
    SideMenuComponentModule,
    SideMenuDesktopComponentModule,
    SideMenuNavComponentModule,
    CookieBannerComponent,
    AsyncPipe,
    IonApp,
    IonRouterOutlet,
    // TODO: Google analytics should be added in a later stadium
    // NgxGoogleAnalyticsModule.forRoot(environment.googleAnalyticsId, [
    //   {
    //     command: 'consent',
    //     values: [
    //       'default',
    //       {
    //         // Set ad_storage and analytics_storage default to denied
    //         ad_storage: 'denied',
    //         analytics_storage: 'denied'
    //       }
    //     ]
    //   }
    // ]),
    // NgxGoogleAnalyticsRouterModule.forRoot({ exclude: ['/reset-password/*'] }),
  ],
  providers: [
    provideIonicAngular(),
    provideHttpClient(withFetch(), withInterceptors([httpInterceptor])),
    provideServiceWorker('ngsw-worker.js', {
      enabled: ! isDevMode() && ! Capacitor.isNativePlatform(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    provideAnimations(),
    provideTransloco({
      config: {
        availableLangs: [
          'nl',
          // English should be added when we want it
          // 'en'
        ],
        defaultLang: 'nl',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: ! isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    provideTranslocoLocale({
      langToLocaleMapping: {
        nl: 'nl-NL',
        // English should be added when we want it
        // en: 'en-US',
      }
    }),
    provideTranslocoTranspiler(FunctionalTranspiler),
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: 'appName', useClass: AppNameResolver },
    { provide: 'copyrightYearAndAppName', useClass: CopyrightYearAndAppNameResolver },
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer
    },
    {
      provide: TitleStrategy,
      useClass: TitleStrategyClass
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: (appInitializerService: AppInitializerService) => {
        return () => appInitializerService.init();
      },
      deps: [AppInitializerService]
    },
    {
      provide: IMAGE_LOADER,
      useValue: imageLoaderFunction,
    },
    // Ionic does not support client hydration at this time:
    // https://github.com/ionic-team/ionic-framework/issues/28625#issuecomment-1843919548
    // https://github.com/angular/angular/issues/52275
    provideClientHydration(withHttpTransferCacheOptions({
      includeRequestsWithAuthHeaders: true,
      includePostRequests: false
    }))
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
