<div
        *transloco="let t"
        role="dialog"
        aria-live="polite"
        aria-label="cookieconsent"
        aria-describedby="cookieconsent:desc"
        class="cookie-consent-banner"
>
  <form novalidate [formGroup]="formGroup" (ngSubmit)="allowSelection()">
    <span>
        {{t('componentsCookieBanner.cookie_usage')}} <a
            routerLink="/terms-privacy"
            rel="noopener noreferrer nofollow"
            target="_blank"
            [title]="t('default.terms_and_privacy')"
    >{{t('default.terms_and_privacy')}}</a>
    </span>
    <br />
    <ion-checkbox
            labelPlacement="end"
            [checked]="true"
            [disabled]="true"
            [title]="t('componentsCookieBanner.labels.necessary')"
    >
      {{t('componentsCookieBanner.labels.necessary')}}
    </ion-checkbox>
    <br />
    <ion-checkbox
            labelPlacement="end"
            formControlName="statistics"
            [title]="t('componentsCookieBanner.labels.statistics')"
    >
      {{t('componentsCookieBanner.labels.statistics')}}
    </ion-checkbox>
    <br />
    <ion-checkbox
            labelPlacement="end"
            formControlName="advertising"
            [title]="t('componentsCookieBanner.labels.advertising')"
    >
      {{t('componentsCookieBanner.labels.advertising')}}
    </ion-checkbox>

    <ion-grid class="ion-no-padding ion-no-margin">
      <ion-row>
        <ion-col size="12" size-md="4">
          <button
                  type="button"
                  class="btn-update followed ion-full-width"

                  [title]="t('componentsCookieBanner.deny')"

                  (click)="denyAll()"
          >
            {{ t('componentsCookieBanner.deny') }}
          </button>
        </ion-col>

        <ion-col size="12" size-md="4">
          <button
                  type="submit"

                  [title]="t('componentsCookieBanner.allow_selection')"

                  class="btn-update followed ion-full-width"
          >
            {{t('componentsCookieBanner.allow_selection')}}
          </button>
        </ion-col>

        <ion-col size="12" size-md="4">
          <button
                  type="button"
                  class="btn-update ion-full-width"

                  [title]="t('componentsCookieBanner.allow_all')"

                  (click)="allowAll()"
          >
            {{t('componentsCookieBanner.allow_all')}}
          </button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</div>
