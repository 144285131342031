import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthDataService } from '../services/crowdbuilding-cms/services/data/personal/auth.data.service';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../environments/environment';

export const authGuard: CanActivateFn = (): boolean => {
  const authDataService = inject(AuthDataService);

  if (authDataService.isLoggedIn()) {
    return true;
  }

  inject(DOCUMENT).location.href = environment.joinUrl;

  return false;
};
