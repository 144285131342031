import { inject, NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { authGuard } from './guards/auth.guard';
import { BreadcrumbRouteDataType } from './types/breadcrumb-route-data.type';
import { PageByParamResolver } from './resolvers/page-by-param.resolver';
import { PageDataService } from './services/crowdbuilding-cms/services/data/module/page.data.service';
import { PageTitleResolver } from './title-resolvers/page.title-resolver';
import { TranslationTitleResolver } from './title-resolvers/translation.title-resolver';
import { redirectToLandingGuard } from './guards/redirect-to-landing.guard';
import { ProfileService } from './services/crowdbuilding-cms/services/profile.service';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule),
    canActivate: [redirectToLandingGuard]
  },
  {
    path: 'users/:user_id',
    loadChildren: () => import('./pages/public-profile/public-profile.module').then(m => m.PublicProfilePageModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/account/account.module').then(m => m.AccountPageModule),
    resolve: {
      profile: () => inject(ProfileService).refresh().then(response => response.data)
    },
    canActivate: [authGuard],
    canActivateChild: [authGuard],
  },
  {
    path: 'discover',
    loadChildren: () => import('./pages/discover/discover.module').then( m => m.DiscoverPageModule)
  },
  {
    path: 'professionals',
    loadChildren: () => import('./pages/professionals/professionals.module').then( m => m.ProfessionalsPageModule)
  },
  {
    path: 'edit',
    loadChildren: () => import('./pages/account/edit/edit.module').then( m => m.AccountEditPageModule)
  },
  {
    path: 'groups',
    loadChildren: () => import('./pages/groups/groups.module').then( m => m.GroupsPageModule)
  },
  {
    path: 'plots',
    loadChildren: () => import('./pages/plots/plots.module').then( m => m.PlotsPageModule)
  },
  {
    path: 'service-providers',
    loadChildren: () => import('./pages/service-providers/service-providers.routes').then( m => m.routes),
  },
  {
    path: 'living-concepts',
    loadChildren: () => import('./pages/living-concepts/living-concepts.module').then( m => m.LivingConceptsPageModule)
  },
  {
    path: 'housing-forms',
    loadChildren: () => import('./pages/housing-forms/housing-forms.module').then( m => m.HousingFormsPageModule)
  },
  {
    path: 'housing-form-types',
    loadChildren: () => import('./pages/housing-form-types/housing-form-types.module').then( m => m.HousingFormTypesModule)
  },
  {
    path: 'themes',
    redirectTo: 'housing-form-types',
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'stories',
    loadChildren: () => import('./pages/stories/stories.module').then( m => m.StoriesPageModule)
  },
  {
    path: 'projects',
    loadChildren: () => import('./pages/projects/projects.module').then(m => m.ProjectsPageModule)
  },
  {
    path: 'terms-privacy',
    loadChildren: () => import('./pages/terms-privacy/terms-privacy.module').then( m => m.TermsPrivacyPageModule)
  },
  {
    path: 'knowledge-base',
    loadChildren: () => import('./pages/knowledge-base/knowledge-base.module').then( m => m.KnowledgeBasePageModule)
  },
  {
    path: 'map',
    loadChildren: () => import('./pages/map/map.routes').then( m => m.routes),
  },
  {
    path: 'news-items',
    loadChildren: () => import('./pages/news-items/news-items.routes').then( m => m.routes),
    data: {
      breadcrumb: <BreadcrumbRouteDataType> {
        label: 'navigation.news',
        labelIsTranslationKey: true,
        disable: true
      },
      titleTranslationKey: 'navigation.news'
    },
    title: TranslationTitleResolver,
  },
  {
    path: 'calendar-items',
    loadChildren: () => import('./pages/calendar-items/calendar-items.routes').then( m => m.routes),
    data: {
      breadcrumb: <BreadcrumbRouteDataType> {
        label: 'navigation.calendar',
        labelIsTranslationKey: true,
        disable: true
      },
      titleTranslationKey: 'navigation.calendar'
    },
    title: TranslationTitleResolver
  },
  {
    path: 'regions',
    loadChildren: () => import('./pages/regions/regions.routes').then( m => m.routes)
  },
  {
    path: '401',
    loadComponent: () => import('./pages/errors/unauthorized/unauthorized.page').then( m => m.UnauthorizedErrorPage)
  },
  {
    path: '403',
    loadComponent: () => import('./pages/errors/forbidden/forbidden.page').then( m => m.ForbiddenErrorPage)
  },
  {
    path: '404',
    loadComponent: () => import('./pages/errors/not-found/not-found.page').then( m => m.NotFoundErrorPage)
  },
  {
    path: '429',
    loadComponent: () => import('./pages/errors/too-many-requests/too-many-requests.page').then( m => m.TooManyRequestsErrorPage)
  },
  {
    path: '500',
    loadComponent: () => import('./pages/errors/server-error/server-error.page').then( m => m.ServerErrorPage)
  },
  {
    path: '503',
    loadComponent: () => import('./pages/errors/service-unavailable/service-unavailable.page').then( m => m.ServiceUnavailableErrorPage)
  },
  {
    path: ':page_id',
    resolve: {
      page: PageByParamResolver
    },
    providers: [PageDataService],
    children: [{
      path: '',
      loadComponent: () => import('./pages/pages/show/show.page').then( m => m.PagesShowPage),
      title: PageTitleResolver,
      resolve: {
        meta_tags: () => inject(PageDataService).peekItem()?.meta_tags
      }
    }]
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home'
  },
  {
    path: '**',
    loadComponent: () => import('./pages/errors/not-found/not-found.page').then( m => m.NotFoundErrorPage)
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
