import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular/standalone';
import { LoadingOptions } from '@ionic/core/dist/types/components/loading/loading-interface';

@Injectable({
  providedIn: 'root'
})
export class LoadingHelper {

  constructor(private loadingController: LoadingController) {
    // Noop
  }

  public async createAndShow(loadingOptions: LoadingOptions = { spinner: 'dots' }): Promise<HTMLIonLoadingElement> {
    const loader = await this.loadingController.create(loadingOptions);

    await loader.present();

    return loader;
  }

}
