import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Translation, TranslocoLoader } from '@jsverse/transloco';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { isPlatformServer } from '@angular/common';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TranslocoHttpLoader implements TranslocoLoader {

  // Init HttpClient here to not use interceptor
  private readonly httpClient = new HttpClient(this.httpBackend);

  constructor(
    private httpBackend: HttpBackend,
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) private _platformId: Object
  ) {
    // Noop
  }

  getTranslation(lang: string) {
    let url = `/assets/i18n/${ lang }.json`;
    if (isPlatformServer(this._platformId)) {
      url = environment.appUrl + url;
    }

    return this.httpClient.get<Translation>(url);
  }

}
