import { Component } from '@angular/core';
import { MenuTagIdEnum } from '../../enums/menu-tag-id.enum';
import { MenuController } from '@ionic/angular/standalone';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { AppInfoHelper } from '../../services/helper/app-info.helper';
import { addIcons } from 'ionicons';
import { close } from 'ionicons/icons';

@Component({
  selector: 'app-side-menu-nav',
  templateUrl: './side-menu-nav.component.html',
  styleUrls: ['./side-menu-nav.component.scss'],
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: ['image-alt'] }],
})
export class SideMenuNavComponent {

  public menuTagId = MenuTagIdEnum.CONTENT_SIDE_MENU;

  constructor(
    public menuController: MenuController,
    public appInfoHelper: AppInfoHelper,
  ) {
    addIcons({ close });
  }

  public closeMenu() {
    this.menuController.close(this.menuTagId);
  }

}
