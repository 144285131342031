import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { PageDataService } from '../services/crowdbuilding-cms/services/data/module/page.data.service';
import { PageInterface } from '../services/crowdbuilding-cms/interfaces/model/page.interface';
import { NavHelper } from '../services/helper/nav.helper';

export const PageByParamResolver: ResolveFn<PageInterface | undefined> = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
) => {
	const pageDataService = inject(PageDataService);
	const navHelper = inject(NavHelper);

	const pageId = route.paramMap.get('page_id');
	if (! pageId) {
		navHelper.navigateByErrorCode(404, { skipLocationChange: false });
		throw new Error('Page id not set');
	}

	return pageDataService.loadItem(encodeURIComponent(pageId))
		.then(() => {
			const page = pageDataService.peekItem();
			if (! page) {
				throw new Error('Page not found');
			}

			return page;
		})
		.catch(() => undefined);
};
