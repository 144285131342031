import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Platform } from '@ionic/angular/standalone';
import { EventsHelper } from './services/helper/events.helper';
import { combineLatest, map, skip, Subject, Subscription, takeUntil, tap } from 'rxjs';
import { AuthHelper } from './services/crowdbuilding-cms/helper/auth.helper';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { CookiePreferencesService } from './services/cookie-preferences.service';
import { SeoHelper } from './services/crowdbuilding-cms/helper/seo.helper';
import { RouterService } from './services/router.service';
import { take } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

  private subscriptions: Array<Subscription> = [];

  private unsubscribe$ = new Subject<void>();

  public showCookieBanner$ = combineLatest([
    this.cookiePreferencesService.showConsentBanner$,
    // We want to show the cookie banner only when the first navigation happened.
    // So, skip the initial value and only take the first url.
    this.routerService.url$.pipe(skip(1), take(1))
  ]).pipe(map(values => values.every(Boolean)));

  public isBrowser = isPlatformBrowser(this._platformId);

  constructor(
    public cookiePreferencesService: CookiePreferencesService,
    private platform: Platform,
    private authHelper: AuthHelper,
    private eventsHelper: EventsHelper,
    private googleAnalyticsService: GoogleAnalyticsService,
    private routerService: RouterService,
    private seoHelper: SeoHelper,
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) private _platformId: Object
  ) {
    this.routerService.url$
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(() => {
          this.seoHelper.updateMetaTagsByRouter();
        })
      )
      .subscribe();

    this.initializeApp();
  }

  ngOnInit() {
    this.subscriptions = [
      this.eventsHelper.subscribe('server:received_401', () => this.authHelper.logout())
    ];
  }

  ngOnDestroy() {
    this.subscriptions = this.subscriptions.filter(subscription => {
      subscription?.unsubscribe();

      return false;
    });

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initializeApp() {
    this.platform.ready().then(() => {
      this.googleAnalyticsService.initialize();
    });
  }

}
