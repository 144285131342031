import { Injectable } from '@angular/core';
import { Meta} from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MetaTagsRouteDataType } from '../../../types/metatags-route-data.type';

@Injectable({
  providedIn: 'root'
})
export class SeoHelper {

  constructor(
    private metaService: Meta,
    private router: Router,
  ) {
    // Noop
  }

  public updateMetaTagsByRouter() {
    let route = this.router.routerState.root.snapshot.root;

    while (route.firstChild) {
      route = route.firstChild;
    }

    const routeMetaTagsData: MetaTagsRouteDataType | undefined = route.data?.['meta_tags'];
    const routeMetaTags = typeof routeMetaTagsData === 'function' ?
      routeMetaTagsData(route.data) :
      routeMetaTagsData;

    this.updateMetaTag('description', 'name', routeMetaTags?.description);
    this.updateMetaTag('og:title', 'property', routeMetaTags?.og?.title);
    this.updateMetaTag('og:description', 'property', routeMetaTags?.og?.description);
    this.updateMetaTag('og:image', 'property', routeMetaTags?.og?.image);
    this.updateMetaTag('og:url', 'property', routeMetaTags?.og?.url);
    this.updateMetaTag('og:type', 'property', routeMetaTags?.og?.type);
    this.updateMetaTag('og:site_name', 'property', routeMetaTags?.og?.site_name);

    this.updateMetaTag('twitter:card', 'name', routeMetaTags?.twitter?.card);
    this.updateMetaTag('twitter:description', 'name', routeMetaTags?.twitter?.description);
    this.updateMetaTag('twitter:image', 'name', routeMetaTags?.twitter?.image);
    this.updateMetaTag('twitter:site', 'name', routeMetaTags?.twitter?.site);
    this.updateMetaTag('twitter:title', 'name', routeMetaTags?.twitter?.title);
    this.updateMetaTag('twitter:url', 'name', routeMetaTags?.twitter?.url);
  }

  private updateMetaTag(tagName: string, attr: 'name' | 'property' = 'name', content: string | undefined | null) {
    if (! content) {
      this.metaService.removeTag(`${attr}='${tagName}'`);
    } else {
      this.metaService.updateTag({
        [attr]: tagName,
        content: content
      });
    }
  }
}