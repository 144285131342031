import { NgModule } from '@angular/core';
import {
  IonButton,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonMenu,
  IonRow,
  IonToolbar
} from '@ionic/angular/standalone';
import { NgOptimizedImage } from '@angular/common';

import { SideMenuNavComponent } from './side-menu-nav.component';
import { ProfileNavListComponentModule } from '../profile-nav-list/profile-nav-list.module';
import { TranslocoDirective } from '@jsverse/transloco';
import { SideMenuNavListComponentModule } from '../side-menu-nav-list/side-menu-nav-list.module';


@NgModule({
  imports: [
    ProfileNavListComponentModule,
    TranslocoDirective,
    SideMenuNavListComponentModule,
    NgOptimizedImage,
    IonMenu,
    IonHeader,
    IonToolbar,
    IonContent,
    IonRow,
    IonCol,
    IonButton,
    IonIcon
  ],
  declarations: [SideMenuNavComponent],
  exports: [SideMenuNavComponent]
})
export class SideMenuNavComponentModule {}
