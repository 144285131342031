import { Component, EventEmitter, Output } from '@angular/core';
import { MenusDataService } from '../../services/crowdbuilding-cms/services/data/all/menus.data.service';
import { Helper } from '../../services/helper/helper';
import { MenuItemInterface } from '../../services/crowdbuilding-cms/interfaces/model/menu-item.interface';

@Component({
  selector: 'app-side-menu-nav-list',
  templateUrl: './side-menu-nav-list.component.html',
  styleUrls: ['./side-menu-nav-list.component.scss'],
})
export class SideMenuNavListComponent {

  @Output() navigationSelected = new EventEmitter<undefined>();

  constructor(
    public menusDataService: MenusDataService,
    public helper: Helper,
  ) {
    // Noop
  }

  public menuItemClicked(event: Event, menuItem: MenuItemInterface) {
    if (! menuItem.link_type) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();
    this.navigationSelected.emit();
  }

}
