import { NgModule } from '@angular/core';
import { IonAccordion, IonAccordionGroup, IonItem } from '@ionic/angular/standalone';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';

import { SideMenuNavListComponent } from './side-menu-nav-list.component';
import { TranslocoDirective } from '@jsverse/transloco';
import {
  DetermineFragmentRouterLinkActiveOptionPipe
} from '../../pipes/determine-fragment-router-link-active-option/determine-fragment-router-link-active-option.pipe';
import {
  GetRouterLinkByRoutePathNamePipe
} from '../../pipes/get-router-link-by-route-path-name/get-router-link-by-route-path-name.pipe';
import { MenusIonItemMenuItemComponent } from '../menus/ion-item-menu-item/ion-item-menu-item.component';
import { DynamicLinksAnchorComponent } from '../dynamic-links/anchor/anchor.component';


@NgModule({
  imports: [
    TranslocoDirective,
    DetermineFragmentRouterLinkActiveOptionPipe,
    GetRouterLinkByRoutePathNamePipe,
    MenusIonItemMenuItemComponent,
    DynamicLinksAnchorComponent,
    IonAccordionGroup,
    IonAccordion,
    IonItem,
    AsyncPipe,
    NgIf,
    NgForOf
  ],
  declarations: [SideMenuNavListComponent],
  exports: [SideMenuNavListComponent]
})
export class SideMenuNavListComponentModule {}
