import { Component } from '@angular/core';
import { IonCheckbox, IonCol, IonGrid, IonRouterLinkWithHref, IonRow } from '@ionic/angular/standalone';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { RouterLink } from '@angular/router';
import { TRANSLOCO_SCOPE, TranslocoDirective } from '@jsverse/transloco';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CookiePreferencesService } from '../../services/cookie-preferences.service';
import { CookieTypeEnum } from '../../enums/cookie-type.enum';

@Component({
  selector: 'app-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss'],
  imports: [
    TranslocoDirective,
    ReactiveFormsModule,
    RouterLink,
    IonRouterLinkWithHref,
    IonCheckbox,
    IonGrid,
    IonRow,
    IonCol
  ],
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: 'components/cookie-banner' }],
  standalone: true
})
export class CookieBannerComponent {

  public formGroup = new FormGroup({
    statistics: new FormControl(this.cookiePreferencesService.isStatisticsPermissionGrantedWithDefault(CookieTypeEnum.GRANTED)),
    advertising: new FormControl(this.cookiePreferencesService.isAdvertisingPermissionGrantedWithDefault(CookieTypeEnum.GRANTED)),
  });

  constructor(
    public cookiePreferencesService: CookiePreferencesService,
    public googleAnalyticsService: GoogleAnalyticsService,
  ) {
    // Noop
  }

  public denyAll() {
    for (const key of Object.keys(this.formGroup.controls)) {
      this.formGroup.get(key)?.setValue(false);
    }

    this.updatePermissions();
  }

  public allowSelection() {
    this.updatePermissions();
  }

  public allowAll() {
    for (const key of Object.keys(this.formGroup.controls)) {
      this.formGroup.get(key)?.setValue(true);
    }

    this.updatePermissions();
  }

  private updatePermissions() {
    this.cookiePreferencesService.updatePermissions(this.formGroup.value);
    this.cookiePreferenceUpdated();
  }

  private cookiePreferenceUpdated() {
    this.cookiePreferencesService.updateShowContentBannerValue();
    this.googleAnalyticsService.updateConsentPermissions();
  }

}
