import { TranslocoTranspilerFunction } from '@jsverse/transloco';
import { environment } from '../../../../environments/environment';

export class AppNameResolver implements TranslocoTranspilerFunction {

  transpile() {
    return environment.appName;
  }

}
