import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { environment } from '../../../environments/environment';
import { AccessTokenService } from '../crowdbuilding-cms/services/data/personal/access-token.service';
import { EventsHelper } from './events.helper';
import { Helper } from './helper';
import { ToastHelper } from './toast.helper';
import { ValidationHelper } from './validation.helper';

@Injectable({
  providedIn: 'root'
})
export class HttpHelper {

  constructor(
    private accessTokenService: AccessTokenService,
    private eventsHelper: EventsHelper,
    private toastHelper: ToastHelper,
    private translocoService: TranslocoService,
    private validationHelper: ValidationHelper,
    private helper: Helper
  ) {
    // Noop
  }

  public handleHttpCodeError(error: HttpErrorResponse) {
    switch (error.status) {
      case 401:
        if (error.url?.startsWith(environment.serverUrl)) {
          // Access token gave 401, so remove access token
          this.accessTokenService.pokeAccessToken(undefined);
          this.eventsHelper.publish('server:received_401');
        }

        this.toastHelper.presentErrorToast(this.translocoService.translate('errors.unauthorized'));
        break;

      case 403:
        this.toastHelper.presentErrorToast(this.helper.isObject(error['error']) && error['error']['message'] ? error['error']['message'] : this.translocoService.translate('errors.forbidden'));
        break;

      case 404:
        this.toastHelper.presentErrorToast(this.translocoService.translate('errors.not_found'));
        break;

      case 422:
        this.validationHelper.showErrorMessageByResponse(error);
        break;

      case 429:
        this.toastHelper.presentErrorToast(this.translocoService.translate('errors.to_many_attempts'));
        break;

      case 500:
      default:
        this.toastHelper.presentErrorToast(this.translocoService.translate('errors.something_went_wrong'));
        break;
    }
  }

}
