<div class="list" *transloco="let t">
  <ng-container *ngIf="menusDataService.header$ | async as header">
    <ion-accordion-group *ngIf="header.items && header.items.length > 0" [multiple]="true">
      <ion-accordion *ngFor="let menuItem of header.items; trackBy: helper.trackByObjectId" [value]="menuItem.id">
        <ion-item slot="header" lines="none" class="header-menu-item">
          <app-dynamic-links-anchor
                  [dynamicLink]="menuItem"
                  (click)="menuItemClicked($event, menuItem)"
          >{{menuItem.title}}</app-dynamic-links-anchor>
        </ion-item>
        <app-menus-ion-item-menu-item
                slot="content"
                *ngFor="let subMenuItem of menuItem.items; trackBy: helper.trackByObjectId"
                [menuItem]="subMenuItem"
                (click)="navigationSelected.emit()"
        ></app-menus-ion-item-menu-item>
      </ion-accordion>
    </ion-accordion-group>
  </ng-container>
</div>
