import { inject } from '@angular/core';
import { HttpErrorResponse, HttpEventType, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { finalize, tap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ToastHelper } from '../services/helper/toast.helper';
import { LoadingHelper } from '../services/helper/loading.helper';
import { HttpHelper } from '../services/helper/http.helper';

export const httpInterceptor: HttpInterceptorFn = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
) => {
  const loadingHelper = inject(LoadingHelper);
  const toastHelper = inject(ToastHelper);
  const httpHelper = inject(HttpHelper);

  const loaderPromise = request.method === 'POST' ?
    loadingHelper.createAndShow() :
    undefined;

  return next(request)
    .pipe(
      tap(httpEvent => {
        if (
          httpEvent.type === HttpEventType.Response &&
          httpEvent.ok &&
          httpEvent.body &&
          typeof httpEvent.body === 'object' &&
          'message' in httpEvent.body &&
          typeof httpEvent.body.message === 'string'
        ) {
          toastHelper.presentSuccessToast(httpEvent.body.message);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.url?.startsWith(environment.serverUrl) || error.url?.startsWith(environment.mapTilerApiUrl)) {
          httpHelper.handleHttpCodeError(error);
        }

        return throwError(() => error);
      }),
      finalize(() => {
        loaderPromise?.then(loader => loader.dismiss());
      })
    );
}
