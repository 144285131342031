<ion-menu side="start" type="overlay" [menuId]="menuTagId" contentId="main-content">
  <ion-header>
    <ion-toolbar class="ion-flex ion-flex-middle">
      <ion-row>
        <ion-col class="ion-flex ion-flex-middle ion-flex-left" size="8">
          <img
                  *transloco="let t"

                  priority
                  class="logo"
                  width="180"
                  height="32"

                  [ngSrc]="appInfoHelper.logo"
                  [alt]="t('imageAlt.logo')"
          />
        </ion-col>
        <ion-col class="ion-flex ion-flex-middle ion-flex-right" size="4">
          <ion-button class="ion-no-margin ion-no-padding" fill="clear" (click)="closeMenu()">
            <ion-icon name="close" slot="icon-only"></ion-icon>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-toolbar>
  </ion-header>
  <ion-content class="side-menu">
    <app-side-menu-nav-list (navigationSelected)="closeMenu()"></app-side-menu-nav-list>
  </ion-content>
</ion-menu>
