import { TranslocoTranspilerFunction } from '@jsverse/transloco';
import { environment } from '../../../../environments/environment';

/**
 * The functional transpiler does not handle multiple functions correctly, right now.
 * Follow: https://github.com/jsverse/transloco/issues/589
 */
export class CopyrightYearAndAppNameResolver implements TranslocoTranspilerFunction {

  transpile(...args: string[]): any {
    return `${new Date().getFullYear()} ${environment.appName}`;
  }

}
