import { ImageLoaderConfig } from '@angular/common';
import { MediaInterface } from '../services/crowdbuilding-cms/interfaces/model/media.interface';
import { MEDIA_IMAGE_WIDTHS } from '../constants/app.constants';

export const imageLoaderFunction = (config: ImageLoaderConfig) => {
  const mediaImage: MediaInterface | undefined = config.loaderParams?.['mediaImage'];
  if (! mediaImage) {
    return config.src;
  }

  const urls = mediaImage.responsive_images?.urls;
  if (! urls) {
    return config.src;
  }

  const givenWidth = config.width;
  if (! givenWidth) {
    return config.src;
  }

  return urls[givenWidth as typeof MEDIA_IMAGE_WIDTHS[number]] || config.src;
};
