import { Inject, Injectable, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import tinycolor, { Instance as TinyColorInstance } from 'tinycolor2';
import { SettingsDataService } from '../crowdbuilding-cms/services/data/personal/settings.data.service';
import { Subject, takeUntil } from 'rxjs';
import { SettingsInterface } from '../crowdbuilding-cms/interfaces/model/settings.interface';

@Injectable({
  providedIn: 'root'
})
export class ThemeColorsHelper implements OnDestroy {

  private readonly ION_PRIMARY_COLOR_KEY = 'primary';
  private readonly ION_SECONDARY_COLOR_KEY = 'secondary';
  private readonly ION_TERTIARY_COLOR_KEY = 'tertiary';

  private _unsubscribe$ = new Subject<void>();

  constructor(
    private settingsData: SettingsDataService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.settingsData.item$
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe(settings => this.setThemeVariablesBySettings(settings));
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  public initializeColors() {
    this.setThemeVariablesBySettings(this.settingsData.peekItem());
  }

  ///////////////////////
  // PRIVATE FUNCTIONS //
  ///////////////////////

  private setThemeVariablesBySettings(settings?: SettingsInterface | null) {
    settings?.primary_color ?
      this.setIonColor(this.ION_PRIMARY_COLOR_KEY, settings.primary_color) :
      this.removeIonColor(this.ION_PRIMARY_COLOR_KEY);

    settings?.secondary_color ?
      this.setIonColor(this.ION_SECONDARY_COLOR_KEY, settings.secondary_color) :
      this.removeIonColor(this.ION_SECONDARY_COLOR_KEY);

    settings?.tertiary_color ?
      this.setIonColor(this.ION_TERTIARY_COLOR_KEY, settings.tertiary_color) :
      this.removeIonColor(this.ION_TERTIARY_COLOR_KEY);

    settings?.background_color ?
      this.setColorWithRgbContrastShadeAndTint('--ion-background-color', settings.background_color) :
      this.removeColorWithRgbContrastShadeAndTint('--ion-background-color');
  }

  private setIonColor(name: string, colorHex: string) {
    this.setColorWithRgbContrastShadeAndTint(`--ion-color-${name}`, colorHex);
  }

  private removeIonColor(name: string) {
    this.removeColorWithRgbContrastShadeAndTint(`--ion-color-${name}`);
  }

  private setColorWithRgbContrastShadeAndTint(name: string, colorHex: string) {
    const color = tinycolor(colorHex);

    const contrast = tinycolor(color.isDark() ? '#ffffff' : '#000000');
    const tint = color.clone().lighten(10);
    const shade = color.clone().darken(10);

    this.document.documentElement.style.setProperty(`${name}`, color.toHexString());
    this.document.documentElement.style.setProperty(`${name}-rgb`, this.getIonRgbFromTinyColorInstance(color));
    this.document.documentElement.style.setProperty(`${name}-contrast`, contrast.toHexString());
    this.document.documentElement.style.setProperty(`${name}-contrast-rgb`, this.getIonRgbFromTinyColorInstance(contrast));
    this.document.documentElement.style.setProperty(`${name}-shade`, shade.toHexString());
    this.document.documentElement.style.setProperty(`${name}-tint`, tint.toHexString());
  }

  private removeColorWithRgbContrastShadeAndTint(name: string) {
    this.document.documentElement.style.removeProperty(`${name}`);
    this.document.documentElement.style.removeProperty(`${name}-rgb`);
    this.document.documentElement.style.removeProperty(`${name}-contrast`);
    this.document.documentElement.style.removeProperty(`${name}-contrast-rgb`);
    this.document.documentElement.style.removeProperty(`${name}-shade`);
    this.document.documentElement.style.removeProperty(`${name}-tint`);
  }

  private getIonRgbFromTinyColorInstance(tinyColorInstance: TinyColorInstance) {
    const rgbaObject = tinyColorInstance.toRgb();

    return `${rgbaObject.r}, ${rgbaObject.g}, ${rgbaObject.b}`;
  }

}
